import baseApi from './base_api.js'

//  查询录音
export function allRecorder(options) {
  return baseApi.post('/allRecorder', {...options})
}

// 增加录音
export function addRecorder(options) {
  return baseApi.post('/addRecorder', {...options})
}

// 删除录音
export function delRecorder(options) {
  return baseApi.post('/delRecorder', {...options})
}
